(function(i, p, w, d, b, $, t){
    var TNT_Interstitial_Ad = {
        'el': {
            'ad_wrapper': '#x-interstitial-ad',
            'ad_close_link': '#x-interstitial-close-link',
            'ad_video_overlay': '#x-video-click-overlay',
        },
        'is_desktop': function() {
            return ($(w).width() > 767) ? true : false;
        },
        'close_ad': function() {
            $(b).find(this.el.ad_wrapper).modal('hide');
        },
        'youtube_player': function() {
            var yt_source = $(this.settings.youtube).attr('src');
                yt_source = yt_source + ((yt_source.indexOf('?') > -1) ? '&' : '?') + 'enablejsapi=1&autoplay=1&rel=0&&showinfo=0&modestbranding=1&controls=1';
            var player = '<div id="x-video-int-ad"><a id="' + this.el.ad_video_overlay.replace('#','') + '" href="' + this.settings.click_tag + '" target="_blank" rel="noopener"></a>' +
                    '<div class="video-responsive">' +
                        '<iframe id="x-interstitial-yt-frame" src="' + yt_source + '" frameborder="0"></iframe>' +
                    '</div>' +
                '</div>';
            return player;
        },
        'youtube_init': function() {
            w.onPlayerReady = function(e) {
                TNT_Interstitial_Ad.youtube_events(e);
            }
            w.onYouTubeIframeAPIReady = function() {
                w.yt_player = new w.YT.Player('x-interstitial-yt-frame', {
                    events: {
                        'onReady': w.onPlayerReady,
                    }
                });
            }
            var tag = d.createElement('script');
                tag.src = '//www.youtube.com/iframe_api';
                tag.async = true;
            var firstScriptTag = d.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        },
        'youtube_events': function(e) {
            e.target.mute(); 
            $(d).find(this.el.ad_video_overlay).on('mouseover', function() {
                e.target.unMute(); 
            });

            var that = this;
            $(b).find(this.el.ad_close_link).on('click', function() {
                that.close_ad();
                e.target.pauseVideo();
            });

            $(d).find(this.el.ad_video_overlay).on('click', function() {
                e.target.pauseVideo(); 
            });
        },
        'image': function() {
            var image = '<a id="x-img-int-ad" href="' + this.settings.click_tag + '" target="_blank" rel="noopener">';
            if (this.settings.mobile_image) {
                image += '<img class="hidden-xs" src="' + this.settings.image + '">';
                image += '<img class="visible-xs" src="' + this.settings.mobile_image + '">';
            } else {
                image += '<img src="' + this.settings.image + '">';
            }
            image += '</a>';
            return image;
        },
        'video_player': function() {
            var onPlay,
                player,
                videoFormat;

            if (this.settings.video_url.indexOf('.mp4') > -1) {
                videoFormat = 'video/mp4';
            } else if (this.settings.video_url.indexOf('.webm') > -1) {
                videoFormat = 'video/webm';
            } else if (this.settings.video_url.indexOf('.ogg') > -1) {
                videoFormat = 'video/ogg';
            } else {
                throw 'Unrecognized video media type.';
            }

            onPlay = (this.settings.autoplay == 'False') ? '' : 'this.muted=true;this.play();';

            player = '<div id="x-video-int-ad">' + 
                '<a id="' + this.el.ad_video_overlay.replace('#','') + '" href="' + this.settings.click_tag + '" target="_blank" rel="noopener"></a>' +
                    '<video width="' + this.settings.width + '" height="' + this.settings.height + '" oncanplay="' + onPlay + '" controls>' +
                        '<source src="' + this.settings.video_url + '" type="' + videoFormat + '">' +
                    '</video>' +
            '</div>';

            return player;
        },
        'video_player_events': function() {
            var closeBtn = d.querySelector(this.el.ad_close_link), 
                overlay = d.querySelector(this.el.ad_video_overlay),
                player = b.querySelector('#x-video-int-ad video'),
                that = this;

            overlay.addEventListener('click', function() {
                player.pause();
            });

            closeBtn.addEventListener('click', function() {
                that.close_ad();
                player.pause();
            });

        },
        'ad_html': function() {
            var html = '' +
            '<style>' +
            this.el.ad_video_overlay + ' { position: absolute; background: transparent; height: 75%; width: 100%; z-index: 2; }' +
            this.el.ad_wrapper + '.modal { text-align: center; padding: 0!important; }' +
            this.el.ad_wrapper + '.modal:before { content: ""; display: inline-block; height: 100%; vertical-align: middle; margin-right: -4px; }' +
            this.el.ad_wrapper + ' .modal-body { padding: 0; }' +
            this.el.ad_wrapper + ' .modal-body * { max-width: 100%!important; }' +
            this.el.ad_wrapper + ' .modal-content, ' + this.el.ad_wrapper + ' .modal-dialog, ' + this.el.ad_wrapper + ' .modal-header { background-color: transparent; border:0; border-radius:0; box-shadow: none; }' +
            this.el.ad_wrapper + ' .modal-dialog { display: inline-block; text-align: left; vertical-align: middle; }' +
            this.el.ad_wrapper + ' .modal-header { padding: 5px; background-color: rgba(0,0,0,.5); color: #fff; }' +
            this.el.ad_wrapper + ' #x-ad-close-cont { font-size: 16px; text-shadow: 1px 1px 5px rgba(0, 0, 0, 1); color: #fff; display: block; padding: 5px 0; text-align: right; }' +
            this.el.ad_wrapper + ' #x-ad-close-cont a { color: #fff; background-color: #000; background-color: rgba(0,0,0,.5); padding: 3px 6px; text-decoration: none; font-size: 18px; font-weight: bold; }' +
            this.el.ad_wrapper + ' #x-ad-close-cont a:hover { opacity: 0.8; }';
            if (this.settings.width && this.settings.height) {
                html += this.el.ad_wrapper + ' .modal-dialog { width: auto; height: auto; max-width: ' + this.settings.width + 'px; max-height: ' + this.settings.height + 'px; background: transparent; }';
                html += this.el.ad_wrapper + ' iframe, ' + this.el.ad_wrapper + ' object, ' + this.el.ad_wrapper + ' video { width: ' + this.settings.width + '; height: ' + this.settings.height + '; }';
            }
            if (this.settings.mobile_image) {
                html += '@media screen and (max-width: 767px) { ' + this.el.ad_wrapper + ' .modal-dialog { max-height: 250px; max-width: 300px; margin: 0 auto; }}';
            }
        html += '</style>' +
            '<div id="' + this.el.ad_wrapper.replace('#','') + '" class="modal fade">' +
                '<div class="modal-dialog">' +
                    '<div id="x-ad-close-cont">' +
                        '<a href="#" id="' + this.el.ad_close_link.replace('#','') + '" onclick="javascript:void(0); return false;"><i class="fas tnt-times"></i> Close</a>' +
                    '</div>' +
                    '<div class="modal-content">' +
                        '<div class="modal-body">';
                            if (this.settings.video_url && this.is_desktop()) {
                                html += this.video_player();
                            } else if (this.settings.youtube && this.is_desktop()) {
                                html += this.youtube_player();
                            } else {
                                html += this.image();
                            }
                            if (this.settings.tracking_pixel) {
                                html += '<div style="display:none;"><img src="' + this.setting.tracking_pixel + '" height="1" width="1" /></div>';
                            }
                        html += '</div>' +
                    '</div>' +
                '</div>' +
            '</div>';
            return html;
        },
        'load': function(settings) {
            this.settings = settings;

            var that = this,
                adCount = 0,
                adCountDown = 0;

            $(d).find(p).hide();
            $(d).find(p).parent().hide();

            $(b).append(this.ad_html());

            if (this.is_desktop()) {
                if (this.settings.video_url) {
                    this.video_player_events();
                } else if (this.settings.youtube) {
                    this.youtube_init();
                }
            }

            $(b).find(this.el.ad_close_link).click(function() {
                that.close_ad();
            });

            $(b).find(this.el.ad_video_overlay).click(function() {
                $(b).find(that.el.ad_close_link).click();
                return true;
            });

            $(b).find(this.el.ad_wrapper).modal({show: true});
            if (this.settings.background && this.settings.background === 'false') {
                $(b).find(this.el.ad_wrapper).data('bs.modal').$backdrop.css('opacity', '0');
            }
        },
    };

    try {
        if (!i) {
            throw 'Friendly iframe required.';
        }
        $(t.cmds).each(function() {
            TNT_Interstitial_Ad.load(this.call());
            return false;
        });
    }
    catch(e){
        if (w.console) {
            w.console.warn(e);
        }
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.inDapIF ? window.parent.jQuery : jQuery),
    (window.__TNT_AD || {})
);